var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"id":"snapshotedit","light":"","width":"400"}},[(_vm.isNew)?_c('h3',[_vm._v(_vm._s(_vm.$t('newsnapshot')))]):_c('h3',[_vm._v(_vm._s(_vm.$t('editsnapshot')))]),_vm._l((_vm.errors),function(error,errorIndex){return _c('v-alert',{key:errorIndex,staticClass:"mt-2 mb-0",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t(("error." + (error.code || 'unspecified'))))+" ")])}),(!_vm.saving)?_c('v-form',{ref:"snapshotform",staticClass:"pt-4",attrs:{"lazy-validation":""},on:{"submit":_vm.saveSnapshot},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":_vm.$t('title'),"rules":[function (v) { return !!v || _vm.$t('mandatory'); }],"required":""},model:{value:(_vm.selected.title),callback:function ($$v) {_vm.$set(_vm.selected, "title", $$v)},expression:"selected.title"}}),_c('v-text-field',{attrs:{"label":_vm.$t('topic'),"rules":[function (v) { return !!v || _vm.$t('mandatory'); }],"required":""},model:{value:(_vm.selected.topic),callback:function ($$v) {_vm.$set(_vm.selected, "topic", $$v)},expression:"selected.topic"}}),_c('v-combobox',{staticClass:"gemeindesuche",attrs:{"placeholder":_vm.$t('municipality'),"append-icon":"mdi-magnify","items":_vm.municipalities,"item-text":"fullname","item-value":"bfsNumber","return-object":"","required":"","rules":[
        function (municipality) { return !!(municipality && municipality.bfsNumber) ||
          _vm.$t('municipalityMandatory'); }
      ],"hide-no-data":!_vm.municipalities.length},on:{"update:search-input":_vm.queryAndSetMunicipalities},model:{value:(_vm.selected.municipality),callback:function ($$v) {_vm.$set(_vm.selected, "municipality", $$v)},expression:"selected.municipality"}},[_c('v-list-item',{attrs:{"slot":"no-data"},slot:"no-data"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('noMatches')))])],1)],1)],1),_c('v-file-input',{attrs:{"accept":".json","label":_vm.$t('file'),"truncate-length":"20","rules":[
        function (file) { return !!_vm.datafile ||
          !!(file && file.name && file.type === 'application/json') ||
          _vm.$t('mandatory'); }
      ],"required":_vm.isNew},on:{"change":_vm.selectFile}},[_c('v-icon',{attrs:{"slot":"append-outer","tag":"a","href":"https://github.com/cividi/spatial-data-package-spec","target":"_blank","rel":"noreferrer"},slot:"append-outer"},[_vm._v(" mdi-help-circle-outline ")])],1),(_vm.datafile)?_c('p',{staticClass:"small mb-0"},[_c('strong',[_vm._v(_vm._s(_vm.$t('currentfile'))+":")]),_vm._v(" "+_vm._s(_vm.datafile)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between mt-4"},[_c('v-btn',{on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1):_c('div',[_c('p',[_vm._v(_vm._s(_vm.status))]),(_vm.progress)?_c('v-progress-linear',{attrs:{"color":"primary","reactive":""},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }